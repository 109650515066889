.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;
  min-height: 50vh;
}

.container h2 {
  margin-bottom: 5rem;
}

.container h4 {
  color: #30333C;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.circle_column {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  flex: 1;
  margin-right: 2.5rem;
}

.circle_column:last-child {
  margin-right: 0rem;
}

.circle {
  border-radius: 50%;
  display: flex;
  align-items: left;
  justify-content: left;
}

.circle img {
  width: 2.5rem;

  min-height: unset;
}

.texts {
  flex-direction: column;
  text-align: left;
}

/* Mobile view */
@media (max-width: 800px) {
  .container h2 {
    margin-bottom: 2rem;
  }

  .columns {
    flex-direction: column;
  }

  .circle_column {
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
  }

  .texts {
    flex-direction: column;
    text-align: left;
  }

  .circle img {
    width: 2.5rem;
  }
}
