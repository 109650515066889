.link {
    color: #97826B;
}

.subpage-padding {
    padding: 14rem 8vw 8rem 8vw;
}

.subpage-padding a {
    color: #97826B;
}

@media (max-width: 768px) {
    .subpage-padding {
        padding:8rem 7vw 8rem 7vw;
    }
  }