
/* Menu */

.App-menu-wrapper {
	width: 100%;
	display: flex;
	position: fixed;
	z-index: 9999999999;
  }
  
  .App-menu {
	flex: 1;
	align-items: center;
	justify-content: space-between;
	top: 0;
	display: flex;
	background-color: #ffffff;
	height: 2.6rem;
	/* box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.10); */
	border-radius: 0.5rem;
	border: 1px solid #f2f3f4;
	margin: 2rem 6.7vw;
	padding: 1.2rem;
  }
  
  .App-menu p {
	margin: 0;
  }
  
  .app-menu-items {
	display: flex;
	align-items: center;
  }
  
  .app-menu-items a {
	margin-right: 2rem;
	white-space: nowrap;
  }

  .app-menu-items a:hover,
  .app-menu-items a:focus {
	text-decoration: underline;
  }

  .app-menu-items a:first-child {
	margin-right: 0rem;
  }

  .app-menu-buttons {
	display: flex-end;
  }
  
  .app-menu-buttons a {
	margin-left: 1rem;
	white-space: nowrap;
  }
  
  @media (max-width: 768px) {
	.App-menu {
	  margin: 1rem 1rem;
	  padding: 1rem;
	}
  
	.app-menu-items a {
	  display: none;
	}

	.app-menu-items a:first-child {
		width: auto;
	  }

	  .app-menu-items a:first-child img {
		height: 2rem;
	  }
  
  
	.app-menu-items a:nth-child(1) {
	  display: block;
	}

	.app-menu-buttons {
		min-width: none;
		display: flex;
	  }

	  .app-menu-buttons a {
		padding: 0.6rem 1rem;
		margin-left: 0.6rem;
		font-size: 0.95rem;
	  }
  }

  @media (max-width: 600px) {

	
  
	/* .app-menu-buttons a:nth-child(2) {
	  display: none;
	} */
  }