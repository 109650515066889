.container {
  width: 100%;
}

.mobile {
  display: none;
}

.section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.image-container {
  width: 50%;
  min-width: 50%;
  height: 100vh;
  border-radius: 0;
  background: rgb(255,255,255);
  background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(88,141,183,1) 100%);
  /* padding: 5%; */
  display: flex;
  flex-direction: center;
  justify-content: center;
  position: relative;
}

.image-container.reverse {
  width: 50%;
  min-width: 50%;
  height: 100vh;
  border-radius: 0;
  background: rgb(255,255,255);
  background: linear-gradient(221deg, rgba(255,255,255,1) 0%, rgba(151,130,107,1) 100%);
  display: flex;
  flex-direction: center;
  justify-content: center;
  position: relative;
}

.feature-image {
  align-self: center;
  width: 80%;
  height: 500px;
}


.text-container {
  width: 50%;
  min-width: 50%;
}

.text-content {
  padding: 4vw 8vw;
  text-align: left;
}

.text-content h2 {
  margin-bottom: 1.5rem;
}

.text-content-tag {
  display: inline-block;
  margin: 0;
  padding: 3px 14px;
  border-radius: 50px;
  background-color: #588DB7;
  color: white;
  font-weight: 700;
  font-size: 1rem;
}

.reverse {
  flex-direction: row-reverse;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center !important;
}

.centered .text-content {
  width: 100%;
  padding: 5rem 0;
}

.feature-image {
  width: 100%;
  height: auto;
  transform: translateZ(0);
  transition: transform 0.1s ease-out;
}

.check {
  width: 380px;
  height: 380px;
}

.parallax {
  position: relative;
  overflow: hidden;
}

.parallax img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 95%;
  height: auto;
  will-change: transform;
}

.ai-icon {
  height: 32px !important;
  width: 32px !important;
  position: relative !important;
  transform: none !important;
}

/* Mobile view */
@media (max-width: 800px) {

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
  }

  .reverse {
    flex-direction: column-reverse;
  }
  
  .image-container {
    width: 100%;
    min-width: 100%;
    height: 40vh;
    border-radius: 0;
    background: rgb(255,255,255);
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(88,141,183,1) 100%);
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .image-container.reverse {
    width: 100%;
    min-width: 100%;
    height: 40vh;
    border-radius: 0;
    background: rgb(255,255,255);
    background: linear-gradient(221deg, rgba(255,255,255,1) 0%, rgba(151,130,107,1) 100%);
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
  }
  
  .feature-image {
    align-self: center;
    width: 80%;
    height: 500px;
  }

  .text-container {
    width: 100%;
    min-width: 100%;
  }

  .text-content {
    padding: 8vw;
  }
  
  .text-content h2 {
    margin-bottom: 0rem;
  }

  .text-content-tag {
    display: inline-block;
    margin: 0;
    padding: 3px 14px;
    border-radius: 50px;
    background-color: #588DB7;
    color: white;
    font-weight: 700;
    font-size: 1rem;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center !important;
  }

  .centered .text-content {
    width: 100%;
    padding: 8vw 0;
  }

  .feature-image {
    width: 100%;
    height: auto;
    transform: translateZ(0);
    transition: transform 0.1s ease-out;
  }

  .check {
    width: 60%;
    height: auto;

    flex-direction: column-reverse;
  }

  .parallax {
    position: relative;
    overflow: hidden;
  }

  .parallax img {
    position: absolute;
    top: -12%;
    left: 0;
    right: 0;
    width: 95%;
    height: auto;
    will-change: transform;
    padding-left: 2%;
  }
}