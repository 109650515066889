.subpage-padding {
    padding: 14rem 8vw 8rem 8vw;
}

.hubspotForm {
    max-width: 900px;
    list-style: lower-alpha !important;
}

@media (max-width: 768px) {
    .subpage-padding {
        padding:8rem 7vw 8rem 7vw;
    }
  }