
/* Footer */

.footer {
  background-color: #282c34;
  padding-bottom: 0rem;
  min-height: 800px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
}

.symbol {
  width: 37%;
  max-width: 600px;
  /* transform: scale(1.2) translateX(180px); */
}

.footer-items {
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  min-height: 630px;
}

.footer-items a {
  color: white;
  margin-bottom: 2rem;
  width: fit-content;
}

.footer p {
  color: white;
}

.footer h2 {
  color: white;
  font-size: 3.8rem;
  margin: 2rem 0 3rem 0;
}

.footer span {
  color: #ADB6C1;
}

  /* Tooltip container */
.social-icon {
  position: relative;
  display: inline-block;
}


/* Tooltip text */
.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with transparency */
  color: #fff !important; /* White text color */
  text-align: center;
  border-radius: 5px;
  padding: 0px 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8rem;
  white-space: nowrap;
}

/* Show the tooltip when hovering over the icon */
.social-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.social-icon:focus .tooltip {
  visibility: visible;
  opacity: 1;
}

.social-icon img {
  width: 36px;
  height: 36px;
  margin-right: 1rem;
}

.store {
  margin: 1.5rem 0;
}

.store-icon img {
  height: 40px;
  margin-right: 1rem;
}

.email {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
.email::before {
  content: '';
  display: inline-block;
  background-image: url('../../assets/email.svg');
  background-size: cover;
  width: 22px;
  height: 22px;
  margin-right: 0.5rem;
  margin-top: 4px;
  transition: 0.3s ease-out;
}

.slogan .mobile {
  display: none;
}

.slogan .desktop {
  display: block;
}



  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 9vw 9vw 9vw !important;
      position: relative;
      min-height: 100vh;
    }
  
    .footer-items {
      min-height: auto;
      width: 100%;
      padding-top: 75px;
    }
    
    .footer span {
      margin-top: 0.8rem;
    }
  
    .footer img {
      width: 40%;
      height: auto;
      padding: 0;
      align-self: center;
      transform: unset;
    }
  
    .symbol {
      width: 100%;
      transform: none;
    }
  
    .social-icon img {
      width: 36px;
      height: 36px;
      margin-right: 0.5rem;
      padding: 0 0.6rem 0 0;
    }

    .store-icon img {
      width: auto;
      height: 40px;
      margin-right: 1rem;
    }
  
    .email {
      margin-bottom: 3rem !important;
    }
  
    .footer h2 {
      font-size: 2rem;
      margin: 4rem 0 2.5rem 0;
    }

    .slogan .desktop {
      display: none;
    }

    .slogan .mobile {
      display: block;
    }
  }